import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GuildContext } from "../utils/context/GuildContext";
import { Title, Flex } from "../utils/styles/index";
import { IoSettingsOutline, IoClipboardOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { MdOutlineFilter9Plus, MdOutlineQueryStats } from "react-icons/md";
import { AiOutlineStar } from "react-icons/ai";
import styled from "styled-components";
import { FaRegMoneyBillAlt } from "react-icons/fa";

const StyledContainer = styled(Container)`
  padding: 2rem 0;
`;

const Section = styled.div`
  background-color: #2c2f33;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const SectionTitle = styled(Title)`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
`;

const StyledTextButton = styled.button`
  background-color: #3a3d42;
  border: none;
  border-radius: 5px;
  padding: 0.75rem 1rem;
  margin-bottom: 0.75rem;
  transition: all 0.2s ease;
  width: 100%;
  text-align: left;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #7289da;
    transform: translateX(5px);
  }
`;

const IconWrapper = styled.div`
  color: #7289da;
`;

export const CategoryPage: React.FC = () => {
  const { guild } = useContext(GuildContext);
  const navigate = useNavigate();

  const renderSection = (title: string, icon: React.ReactNode, buttons: React.ReactNode) => (
    <Section>
      <Flex justifyContent="space-between" alignItems="center">
        <SectionTitle>{title}</SectionTitle>
        <IconWrapper>{icon}</IconWrapper>
      </Flex>
      {buttons}
    </Section>
  );

  return (
    <StyledContainer>
            {renderSection(
        "Financeiro",
        <FaRegMoneyBillAlt size={28} />,
        <Row>
          <Col sm>
            <StyledTextButton onClick={() => navigate("/dashboard/payments")}>
              Gerenciar Pagamentos
            </StyledTextButton>
          </Col>
          <Col sm>
            <StyledTextButton onClick={() => navigate("/dashboard/invoices")}>
              Histórico de Faturas
            </StyledTextButton>
          </Col>
        </Row>
      )}
      {renderSection(
        "Configurações Básicas",
        <IoSettingsOutline size={28} />,
        <>
          <Row>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/prefix")}>
                Prefixo para comandos
              </StyledTextButton>
            </Col>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/welcome")}>
                Editar Bem-Vindo
              </StyledTextButton>
            </Col>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/instagram")}>
                Editar Instagram
              </StyledTextButton>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/permissions")}>
                Permissões de Moderação
              </StyledTextButton>
            </Col>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/pd")}>
                Permissões de PD
              </StyledTextButton>
            </Col>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/migrar")}>
                Configuração de Migração
              </StyledTextButton>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/registro")}>
                Configuração de Registro
              </StyledTextButton>
            </Col>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/activemember")}>
                Configuração de Membro Ativo
              </StyledTextButton>
            </Col>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/verified")}>
                Configuração de Verificado
              </StyledTextButton>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/tellonym")}>
                Configuração de Tellonym
              </StyledTextButton>
            </Col>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/sejamembro")}>
                Configuração de Seja Membro
              </StyledTextButton>
            </Col>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/influencer")}>
                Configuração de Influencer
              </StyledTextButton>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/verifyconfig")}>
                Configuração Ficha de Verificação
              </StyledTextButton>
            </Col>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/panelaconfig")}>
                Configuração Panela
              </StyledTextButton>
            </Col>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/protcargo")}>
                Configuração Proteção de Cargo
              </StyledTextButton>
            </Col>
          </Row>
          <Row>
            <Col sm>
            <StyledTextButton onClick={() => navigate("/dashboard/support")}>
                Configuração Suporte
              </StyledTextButton>
            </Col>
          </Row>
        </>
      )}

      {renderSection(
        "Configurações de Contadores",
        <MdOutlineFilter9Plus size={28} />,
        <Row>
          <Col sm>
            <StyledTextButton onClick={() => navigate("/dashboard/voicecount")}>
              Contador de Call
            </StyledTextButton>
          </Col>
          <Col sm>
            <StyledTextButton onClick={() => navigate("/dashboard/membercount")}>
              Contador de Membros
            </StyledTextButton>
          </Col>
          <Col sm>
            <StyledTextButton onClick={() => navigate("/dashboard/contadorhora")}>
              Configuração de Contador de Hora
            </StyledTextButton>
          </Col>
        </Row>
      )}

      {renderSection(
        "Configurações de Logs",
        <IoClipboardOutline size={28} />,
        <Row>
          <Col sm>
            <StyledTextButton onClick={() => navigate("/dashboard/logs")}>
              Logs de moderação
            </StyledTextButton>
          </Col>
        </Row>
      )}

      {renderSection(
        "Informações do Servidor",
        <MdOutlineQueryStats size={28} />,
        <>
          <Row>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/bans")}>
                Banimentos ativos
              </StyledTextButton>
            </Col>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/pds")}>
                Primeiras Damas
              </StyledTextButton>
            </Col>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/membrosativos")}>
                Membros Ativos
              </StyledTextButton>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <StyledTextButton onClick={() => navigate("/dashboard/verificados")}>
                Verificados
              </StyledTextButton>
            </Col>
          </Row>
        </>
      )}
    </StyledContainer>
  );
};