import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Container, Modal, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { GuildMenuItem } from '../components/GuildMenuItem';
import { GuildContext } from '../utils/context/GuildContext';
import { useFetchGuilds } from '../utils/hooks/useFetchGuilds';
import { useFetchUser } from '../utils/hooks/useFetchUser';
import { MoonLoader } from 'react-spinners';
import { PartialGuild } from '../utils/types';
import { Button as StyledButton } from '../utils/styles';
import { CheckCircle, Copy, UserPlus } from 'lucide-react';
import { createBotOrder, verifyPayment } from '../utils/api';

const PageWrapper = styled.div`
  background-color: #18191D;
  color: #ffffff;
  min-height: 100vh;
  padding: 2rem 0;
`;

const ContentContainer = styled(Container)`
  max-width: 800px;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  font-weight: 300;
  margin-bottom: 2rem;
  color: #ffffff;
`;

const CopyButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  background-color: #4a4a4a;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  &:hover {
    background-color: #5a5a5a;
  }
`;

const GuildList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const GuildItemWrapper = styled.div`
  cursor: pointer;
`;

const BuyButton = styled(StyledButton)`
  background-color: #7289da;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  margin-top: 1.5rem;
  align-self: center;
  &:hover {
    background-color: #5a6fb8;
  }
`;

const CopySuccessMessage = styled.p`
  color: #4caf50;
  margin-top: 0.5rem;
`;

const NoPaymentMessage = styled.p`
  color: #ff0000;
  margin-top: 0.5rem;
`;

const DarkModal = styled(Modal)`
  .modal-content {
    background-color: #212428;
    color: #ffffff;
    border-radius: 8px;
  }
  .modal-header,
  .modal-footer {
    border: none;
  }
  .modal-body {
    background-color: #212428;
  }
`;

const QRCodeImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 1rem;
`;

const SuccessMessage = styled.div`
  background-color: #2ecc71;
  color: white;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

const StepContainer = styled.div`
  background-color: #2c2f33;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
`;

const StepTitle = styled.h4`
  color: #7289da;
  margin-bottom: 0.5rem;
`;

const StepContent = styled.p`
  margin-bottom: 0.5rem;
`;

export const MenuPage: React.FC = () => {
  const navigate = useNavigate();
  const { updateGuild } = useContext(GuildContext);
  const { guilds, loading } = useFetchGuilds();
  const { user } = useFetchUser();
  const [showModal, setShowModal] = useState(false);
  const [guildId, setGuildId] = useState('');
  const [orderId, setOrderId] = useState<string | null>(null);
  const [botInviteLink, setBotInviteLink] = useState('');
  const [paymentStep, setPaymentStep] = useState(0);
  const [qrCodeBase64, setQrCodeBase64] = useState<string | null>(null);
  const [qrCodeText, setQrCodeText] = useState<string | null>(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyLinkSuccess, setCopyLinkSuccess] = useState(false);
  const [noPaymentMessage, setNoPayment] = useState(false);

  const handleCopyClick = async () => {
    if (qrCodeText) {
      try {
        await navigator.clipboard.writeText(qrCodeText);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    }
  };
  
  const handleAddBot = () => {
    if (botInviteLink) {
      window.open(botInviteLink, '_blank');
    }
  };

  const handleClick = (guild: PartialGuild) => {
    updateGuild(guild);
    navigate('/dashboard/categories');
  };

  const handlePurchase = () => {
    if (!user) {
      navigate('/login');
      return;
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPaymentStep(0);
    setGuildId('');
    setOrderId(null);
    setQrCodeBase64(null);
    setQrCodeText(null);
  };

  const handleSubmitGuildId = async () => {
    try {
      const response = await createBotOrder(guildId, user?.accessToken);
      const { orderId, qrCode, qrCodeBase64 } = response.data;
      setOrderId(orderId);
      setQrCodeText(qrCode);
      setQrCodeBase64(qrCodeBase64);
      setPaymentStep(1);
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };

  const handleCheckPaymentStatus = async () => {
    if (!orderId) return;
    try {
      const response = await verifyPayment(guildId,user?.accessToken, orderId);
      const { isPaid, inviteLink} = response.data;
      if (isPaid) {
        setBotInviteLink(inviteLink);
        setPaymentStep(2);
      } else {
        setNoPayment(true);
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
    }
  };

  return (
    <PageWrapper>
      <ContentContainer>
        <Heading>Selecione um servidor</Heading>
        {loading ? (
          <LoaderWrapper>
            <MoonLoader size={40} color="#7289da" />
          </LoaderWrapper>
        ) : (
          <GuildList>
            {guilds && guilds.map((guild) => (
              <GuildItemWrapper key={guild.id} onClick={() => handleClick(guild)}>
                <GuildMenuItem guild={guild} />
              </GuildItemWrapper>
            ))}
          </GuildList>
        )}
        <BuyButton variant="primary" onClick={handlePurchase}>
          Comprar Bot
        </BuyButton>
      </ContentContainer>

      <DarkModal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Comprar Bot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {paymentStep === 0 && (
            <Form>
              <Form.Group>
                <Form.Label>ID do Servidor</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o ID do servidor"
                  value={guildId}
                  onChange={(e) => setGuildId(e.target.value)}
                />
              </Form.Group>
            </Form>
          )}
          {paymentStep === 1 && (
            <>
              <p>Faça o pagamento usando o seguinte QR Code:</p>
              {qrCodeBase64 && (
                <QRCodeImage src={`data:image/png;base64,${qrCodeBase64}`} alt="QR Code for payment" />
              )}
              <p>Ou use a chave PIX abaixo:</p>
              <pre>{qrCodeText}</pre>
              <CopyButton variant="secondary" onClick={handleCopyClick}>
              <Copy size={16} style={{ marginRight: '0.5rem' }} />
              Copiar Copia e Cola
              </CopyButton>
              {copySuccess && <CopySuccessMessage>Copiado com sucesso!</CopySuccessMessage>}
              <p>ID do Pedido: <strong>{orderId}</strong></p>
              {noPaymentMessage && <NoPaymentMessage>Pagamento ainda não foi efetuado!</NoPaymentMessage>}
              <p>Após o pagamento, clique em "Verificar Pagamento" abaixo.</p>
            </>
          )}
          {paymentStep === 2 && (
            <>
            <SuccessMessage>
              <CheckCircle size={24} style={{ marginRight: '0.5rem' }} />
              <span>Pagamento confirmado! Seu bot está pronto para ser adicionado.</span>
            </SuccessMessage>

            <StepContainer>
              <StepTitle>1. Adicione o Bot ao Seu Servidor</StepTitle>
              <StepContent>Clique no botão abaixo para adicionar o bot ao servidor selecionado:</StepContent>
              <CopyButton variant="primary" onClick={handleAddBot}>
                <UserPlus size={16} style={{ marginRight: '0.5rem' }} />
                Adicionar Bot ao Servidor
              </CopyButton>
            </StepContainer>

            <StepContainer>
              <StepTitle>2. Configure o Bot</StepTitle>
              <StepContent>
                Após adicionar o bot, você pode configurá-lo usando nosso painel de controle.
                Visite a página de dashboard para começar a personalização.
              </StepContent>
              <StyledButton variant="secondary" onClick={() => navigate('/dashboard')}>
                Ir para o Dashboard
              </StyledButton>
            </StepContainer>

            <StepContainer>
              <StepTitle>3. Precisa de Ajuda?</StepTitle>
              <StepContent>
                Se você tiver alguma dúvida ou precisar de assistência, nossa equipe de suporte está pronta para ajudar.
              </StepContent>
              <StyledButton 
                variant="secondary" 
                onClick={() => window.open('https://discord.gg/PrY9sJwSEY', '_blank')}
              >
                Contatar Suporte
              </StyledButton>
            </StepContainer>
          </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {paymentStep === 0 && (
            <StyledButton variant="primary" onClick={handleSubmitGuildId}>
              Criar Pedido
            </StyledButton>
          )}
          {paymentStep === 1 && (
            <StyledButton variant="primary" onClick={handleCheckPaymentStatus}>
              Verificar Pagamento
            </StyledButton>
          )}
          <StyledButton variant="secondary" onClick={handleCloseModal}>
            Fechar
          </StyledButton>
        </Modal.Footer>
      </DarkModal>
    </PageWrapper>
  );
};