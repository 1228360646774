import { useCallback, useEffect, useState } from "react";
import { getGuildTransactions } from "../api"; // You'll need to create this API function
import { Transaction } from "../types"; // You'll need to define this type

export function useGuildTransactions(guildId: string) {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchTransactions = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getGuildTransactions(guildId);
      setTransactions(data);
    } catch (err) {
      console.error("Error fetching guild transactions:", err);
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [guildId]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const refetch = useCallback(async () => {
    await fetchTransactions();
  }, [fetchTransactions]);

  return { transactions, loading, error, refetch };
}