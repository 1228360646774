import { useEffect, useState } from "react";
import { getGuildConfig, getGuildRoles } from "../api";
import { GuildConfigType, GuildRole } from "../types";

export type rolePermissions = {
  permId: string;
  canSet: string[];
}

export function useGuildFetchRoleProtection(guildId: string) {
  const [config, setConfig] = useState<GuildConfigType>();
  const [roles, setRoles] = useState<GuildRole[]>();
  const [roleProtectionConfig, setRoleProtectionConfig] = useState<rolePermissions[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getGuildConfig(guildId)
      .then(({ data }) => {
        setConfig(data);
        if (!data.roleProtectionConfig || data.roleProtectionConfig.length === 0) {
          data.roleProtectionConfig = [{ permId: "", canSet: [] }];
        }
        setRoleProtectionConfig(data.roleProtectionConfig);
        return getGuildRoles(guildId);
      })
      .then(({ data }) => {
        setRoles(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [guildId]);

  return { config, roles, roleProtectionConfig, loading, setRoleProtectionConfig };
}