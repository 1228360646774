import { useEffect, useState } from "react";
import {
  getGuildConfig,
  getGuildRoles,
  getGuildTextChannels,
} from "../api";
import { GuildConfigType, GuildRole, PartialGuildChannel } from "../types";

export function useGuildSupport(guildId: string) {
  const [config, setConfig] = useState<GuildConfigType>();
  const [channels, setChannels] = useState<PartialGuildChannel[]>();
  const [roles, setRoles] = useState<GuildRole[]>();
  const [selectedSuporteChannel, setSelectedSuporteChannel] = useState<string>("");
  const [suporteChannelMsg, setSuporteChannelMsg] = useState<string>("");
  const [suporteColor, setSuporteColor] = useState<string>("");
  const [suporteChannelImg, setSuporteChannelImg] = useState<string>("");
  const [suporteRoles, setSuporteRoles] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getGuildConfig(guildId)
      .then(({ data }) => {
        setConfig(data);
        setSelectedSuporteChannel(data.suporteChannelId || "");
        setSuporteChannelMsg(data.suporteChannelText || "");
        setSuporteChannelImg(data.suporteChannelImg || "");
        setSuporteRoles(data.suporteRoles);
        setSuporteColor(data.suporteColor);
        getGuildTextChannels(guildId).then(({ data }) => {
          setChannels(data);
          getGuildRoles(guildId).then(({ data }) => {
            setRoles(data);
          });
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return {
    config,
    channels,
    roles,
    loading,
    selectedSuporteChannel,
    setSelectedSuporteChannel,
    suporteChannelMsg,
    setSuporteChannelMsg,
    suporteChannelImg,
    setSuporteChannelImg,
    suporteRoles,
    setSuporteRoles,
    suporteColor,
    setSuporteColor,
  };
}