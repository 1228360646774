import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { MoonLoader } from 'react-spinners';
import { GuildContext } from '../../utils/context/GuildContext';
import { Title, Flex, Button } from '../../utils/styles';
import toast, { Toaster } from 'react-hot-toast';
import styled from 'styled-components';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ChevronDown, ChevronUp, Check, X } from 'lucide-react';

import { useGuildTransactions } from '../../utils/hooks/useGuildTransactions';

const TransactionTable = styled.div`
  background-color: #2a2d3a;
  color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 2fr 1fr;
  background-color: #1e2130;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding: 1rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 2fr 1fr;
  padding: 1rem;
  border-bottom: 1px solid #3a3f55;

  &:hover {
    background-color: #3a3f55;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
    padding: 1rem 0.5rem;
  }
`;

const TableCell = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: space-between;

    &::before {
      content: attr(data-label);
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }
  }
`;

const StatusBadge = styled.span<{ status: string }>`
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: ${({ status }) => 
    status === 'approved' ? '#10B981' : 
    status === 'pending' ? '#F59E0B' : 
    '#EF4444'};
  color: white;
`;

const SortButton = styled(Button)`
  background: none;
  border: none;
  color: #ffffff;
  padding: 0;
  font-size: 1rem;
  display: flex;
  align-items: center;

  &:hover, &:focus {
    color: #7289da;
  }
`;

interface Transaction {
  id: number;
  guildId: string;
  botId: string;
  userId: string;
  username: string;
  price: string;
  status: string;
  mpOrderId: string;
  delivered: boolean;
  deliveryDate: string;
}

export const GuildTransactionsPage: React.FC = () => {
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || "";
  const { transactions, loading, error } = useGuildTransactions(guildId);
  const [sortedTransactions, setSortedTransactions] = useState<Transaction[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof Transaction; direction: 'ascending' | 'descending' } | null>(null);

  useEffect(() => {
    if (transactions) {
      setSortedTransactions([...transactions]);
    }
  }, [transactions]);

  const requestSort = (key: keyof Transaction) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    setSortedTransactions(transactions.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    }));
  };

  const getSortIcon = (key: keyof Transaction) => {
    if (!sortConfig || sortConfig.key !== key) {
      return <ChevronDown size={16} />;
    }
    return sortConfig.direction === 'ascending' ? <ChevronUp size={16} /> : <ChevronDown size={16} />;
  };

  const formatStatus = (status: string) => {
    switch (status.toLowerCase()) {
      case 'approved':
        return 'Aprovado';
      case 'pending':
        return 'Pendente';
      default:
        return 'Rejeitado';
    }
  };

  return (
    <Container style={{ padding: '35px 15px' }}>
      <Toaster position="bottom-right" reverseOrder={false} />
      <Title>Histórico de Transações</Title>
      {loading ? (
        <Flex justifyContent="center">
          <MoonLoader size={40} color="white" />
        </Flex>
      ) : error ? (
        <Flex justifyContent="center">
          <p>Erro ao carregar transações. Por favor, tente novamente mais tarde.</p>
        </Flex>
      ) : (
        <TransactionTable>
          <TableHeader>
            <SortButton variant='primary' onClick={() => requestSort('id')}>
              ID {getSortIcon('id')}
            </SortButton>
            <SortButton variant='primary' onClick={() => requestSort('username')}>
              Usuário {getSortIcon('username')}
            </SortButton>
            <SortButton variant='primary' onClick={() => requestSort('price')}>
              Valor {getSortIcon('price')}
            </SortButton>
            <SortButton variant='primary' onClick={() => requestSort('status')}>
              Status {getSortIcon('status')}
            </SortButton>
            <SortButton variant='primary' onClick={() => requestSort('deliveryDate')}>
              Data {getSortIcon('deliveryDate')}
            </SortButton>
            <div>Entregue</div>
          </TableHeader>
          {sortedTransactions.map((transaction) => (
            <TableRow key={transaction.id}>
              <TableCell data-label="ID">{transaction.id}</TableCell>
              <TableCell data-label="Usuário">{transaction.username}</TableCell>
              <TableCell data-label="Valor">
                <span>R$ {parseFloat(transaction.price).toFixed(2)}</span>
              </TableCell>
              <TableCell data-label="Status">
                <StatusBadge status={transaction.status.toLowerCase()}>
                  {formatStatus(transaction.status)}
                </StatusBadge>
              </TableCell>
              <TableCell data-label="Data">
                {format(new Date(transaction.deliveryDate), "dd 'de' MMM 'de' yyyy, HH:mm", { locale: ptBR })}
              </TableCell>
              <TableCell data-label="Entregue">
                {transaction.delivered ? (
                  <Check size={20} color="#10B981" />
                ) : (
                  <X size={20} color="#EF4444" />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TransactionTable>
      )}
    </Container>
  );
};