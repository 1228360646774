import React from 'react';
import styled from 'styled-components';
import { Navbar, Nav, Container } from "react-bootstrap";
import { FaDiscord, FaQuestionCircle } from "react-icons/fa";

const StyledNavbar = styled(Navbar)`
  background-color: #2c2f33;
  padding: 0.5rem 1rem;
`;

const BrandSection = styled(Navbar.Brand)`
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 1.5rem;
  
  img {
    margin-right: 10px;
  }
`;

const StyledNav = styled(Nav)`
  .nav-link {
    color: #ffffff;
    margin-left: 15px;
    display: flex;
    align-items: center;
    
    &:hover {
      color: #7289da;
    }

    svg {
      margin-right: 5px;
    }
  }
`;

const MainNavbar: React.FC = () => {
  return (
    <StyledNavbar expand="lg" variant="dark">
      <Container>
        <BrandSection href="/">
          <img
            alt=""
            src="/logo192.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />
          Faz Bots
        </BrandSection>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <StyledNav className="ms-auto">
            <Nav.Link href="https://discord.gg/vSntKpcKAr" target="_blank">
              <FaQuestionCircle /> Servidor de Suporte
            </Nav.Link>
            <Nav.Link href="https://fazbots.com/api/auth/login">
              <FaDiscord size={20} /> Login
            </Nav.Link>
          </StyledNav>
        </Navbar.Collapse>
      </Container>
    </StyledNavbar>
  );
};

export default MainNavbar;