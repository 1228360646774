import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { BsArrowBarLeft } from 'react-icons/bs';
import { FaQuestionCircle } from 'react-icons/fa';
import { CgLogOut } from 'react-icons/cg';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { GuildContext } from '../utils/context/GuildContext';
import { useFetchUser } from '../utils/hooks/useFetchUser';
import { getIconURL, getUserIconURL } from '../utils/helpers';

const StyledNavbar = styled(Navbar)`
  background-color: #2c2f33;
  padding: 0.5rem 1rem;
`;

const BrandSection = styled.div`
  display: flex;
  align-items: center;
`;

const GuildIcon = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 0 15px;
`;

const GuildName = styled.span`
  color: #ffffff;
  font-size: 1.2rem;
`;

const UserIcon = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
`;

const StyledNavDropdown = styled(NavDropdown)`
  .dropdown-menu {
    background-color: #23272a;
    border: none;
  }

  .dropdown-item {
    color: #ffffff;
    &:hover {
      background-color: #7289da;
    }
  }

  .dropdown-divider {
    border-top-color: #4f545c;
  }
`;

export const AppBar: React.FC = () => {
  const { guild } = useContext(GuildContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useFetchUser();
  const [expanded, setExpanded] = useState(false);

  if (!guild) return <Navigate replace to="/menu" />;

  const handleNavigation = () => {
    if (location.pathname === '/dashboard/categories') {
      navigate('/menu');
    } else {
      navigate('/dashboard/categories');
    }
  };

  return (
    <StyledNavbar expand="lg" variant="dark">
      <Container fluid>
        <BrandSection>
          <BsArrowBarLeft size={30} cursor='pointer' onClick={handleNavigation} />
          <GuildIcon src={getIconURL(guild)} alt="guild icon" />
          <GuildName>{guild.name}</GuildName>
        </BrandSection>
        <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="responsive-navbar-nav">
          <UserIcon src={getUserIconURL(user)} alt='user profile' />
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            {!expanded ? (
              <StyledNavDropdown
                align="end"
                title={<UserIcon src={getUserIconURL(user)} alt='user profile' />}
                id="user-nav-dropdown"
              >
                <NavDropdown.Item href="https://discord.gg/e4mpyys3Up"><FaQuestionCircle /> Suporte</NavDropdown.Item>
                <NavDropdown.Item href="#logout"><CgLogOut /> Sair</NavDropdown.Item>
              </StyledNavDropdown>
            ) : (
              <>
                <Nav.Link href="https://discord.gg/e4mpyys3Up"><FaQuestionCircle /> Suporte</Nav.Link>
                <Nav.Link href="#logout"><CgLogOut /> Sair</Nav.Link>
                <Navbar.Text>
                  Usuário: {user?.username}#{user?.discriminator}
                </Navbar.Text>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </StyledNavbar>
  );
};