import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { Button, Flex, Title } from "../../utils/styles";
import { MoonLoader } from "react-spinners";
import Select from "react-select";
import { GuildContext } from "../../utils/context/GuildContext";
import { updateRoleProtectionConfig } from "../../utils/api";
import { useGuildFetchRoleProtection } from "../../utils/hooks/useFetchGuildRoleProtection";

interface SelectOption {
  value: string;
  label: string;
}

export const GuildRoleProtectionPage: React.FC = () => {
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || "";
  const { config, roles, roleProtectionConfig, loading, setRoleProtectionConfig } = useGuildFetchRoleProtection(guildId);

  const options: SelectOption[] = roles?.map((role) => ({
    value: role.id,
    label: role.name,
  })) || [];

  const handleSubmit = () => {
    try {
      updateRoleProtectionConfig(guildId, roleProtectionConfig);
      toast.success("Configuração atualizada com sucesso!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar a configuração!");
    }
  };

  const handleAddPermission = () => {
    setRoleProtectionConfig([...roleProtectionConfig, { 
      permId: "",
      canSet: []
    }]);
  };

  const handleRemovePermission = (index: number) => {
    const updatedConfig = [...roleProtectionConfig];
    updatedConfig.splice(index, 1);
    setRoleProtectionConfig(updatedConfig);
  };

  const handleChangePermission = (index: number, value: string) => {
    const updatedConfig = [...roleProtectionConfig];
    updatedConfig[index].permId = value;
    setRoleProtectionConfig(updatedConfig);
  };

  const handleChangeCanSet = (permissionIndex: number, selectedOptions: SelectOption[]) => {
    const updatedConfig = [...roleProtectionConfig];
    updatedConfig[permissionIndex].canSet = selectedOptions.map(option => option.value);
    setRoleProtectionConfig(updatedConfig);
  };

  const colorStyles = {
    control: (Styles: object) => ({
      ...Styles,
      backgroundColor: "#1112155d",
      border: "1px solid #6f6f6f",
      outline: "none",
      fontFamily: "DM Sans",
      color: "#fff",
      height: "auto",
    }),
    option: (
      Styles: object,
      {
        data,
        isDisabled,
        isFocused,
        isSelected,
      }: {
        data: object;
        isDisabled: boolean;
        isFocused: boolean;
        isSelected: boolean;
      }
    ) => {
      return {
        ...Styles,
        color: "#fff",
        background: isFocused
          ? "#18191d93"
          : isSelected
          ? "#41444f"
          : "#18191d",
        fontFamily: "DM Sans",
        border: "none",
        borderRadius: "none",
      };
    },
    multiValue: (Styles: object) => {
      return {
        ...Styles,
        color: "#ffffff",
        fontFamily: "DM Sans",
        backgroundColor: "#3a3d4692",
      };
    },
    singleValue: (Styles: object) => {
      return { ...Styles, color: "#fff", fontFamily: "DM Sans" };
    },
    multiValueLabel: (Styles: object) => {
      return {
        ...Styles,
        color: "#ffffff",
      };
    },
    menuList: (Styles: object) => {
      return {
        background: "#18191d93",
        height: "200px",
        overflow: "scroll",
      };
    },
    placeholder: (Styles: object) => {
      return { ...Styles, color: "#ffffff" };
    },
  };

  return (
    <Container style={{ padding: "35px 15px" }}>
      <div>
        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
      <Title>Configuração de Proteção de Cargos</Title>
      {config && roles && !loading ? (
        <>
          <section style={{ marginTop: "15px" }}>
            <div>
              <Title style={{ paddingTop: "5px", marginBottom: "3px" }}>
                Permissões de Cargos
              </Title>
              {roleProtectionConfig.map((permission, permissionIndex) => (
                <div key={permissionIndex} style={{ marginBottom: "20px", padding: "15px", border: "1px solid #6f6f6f", borderRadius: "5px" }}>
                  <Select
                    options={options}
                    styles={colorStyles}
                    value={options.find(opt => opt.value === permission.permId)}
                    onChange={(selectedOption) => handleChangePermission(permissionIndex, selectedOption?.value || "")}
                    placeholder="Selecione o cargo protegido"
                  />
                  <div style={{ marginTop: "10px" }}>
                    <Select
                      isMulti
                      options={options}
                      styles={colorStyles}
                      value={options.filter(opt => permission.canSet.includes(opt.value))}
                      onChange={(selectedOptions) => handleChangeCanSet(permissionIndex, selectedOptions as SelectOption[])}
                      placeholder="Selecione os cargos que podem definir"
                    />
                  </div>
                  <Button variant="negative" onClick={() => handleRemovePermission(permissionIndex)} style={{ marginTop: "10px" }}>
                    Remover Permissão
                  </Button>
                </div>
              ))}
              <Flex justifyContent="flex-end" style={{ marginTop: "20px" }}>
                <Button
                  variant="positive"
                  type="button"
                  style={{ marginRight: "8px" }}
                  onClick={handleAddPermission}
                >
                  Adicionar Permissão
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  Salvar
                </Button>
              </Flex>
            </div>
          </section>
        </>
      ) : (
        <Flex justifyContent="center">
          <MoonLoader size={40} color="white" />
        </Flex>
      )}
    </Container>
  );
};