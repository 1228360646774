import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Container, Modal } from 'react-bootstrap';
import { MoonLoader } from 'react-spinners';
import { GuildContext } from '../../utils/context/GuildContext';
import { Title, Button, Flex } from '../../utils/styles';
import toast, { Toaster } from 'react-hot-toast';
import { useFetchGuildPayments } from '../../utils/hooks/useFetchGuildPayments';
import { createRenewalOrder, verifyRenewal } from '../../utils/api';
import { Copy, CheckCircle, Calendar, DollarSign, RefreshCw } from 'lucide-react';
import { useFetchUser } from '../../utils/hooks/useFetchUser';

const PageWrapper = styled.div`
  background-color: #18191D;
  color: #ffffff;
  min-height: 100vh;
  padding: 2rem 0;
`;

const ContentContainer = styled(Container)`
  max-width: 800px;
`;

const Card = styled.div`
  background-color: #23252A;
  border-radius: 16px;
  padding: 2rem;
  margin-top: 2rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 48px rgba(0, 0, 0, 0.3);
  }
`;

const StatusTitle = styled.h2`
  color: #FFFFFF;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

const StatusInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const StatusText = styled.p`
  font-size: 1.2rem;
  color: #CCCCCC;
  margin-left: 1rem;
  margin-bottom: 0;
`;

const DaysLeft = styled.span`
  font-weight: bold;
  color: #4CAF50;
  font-size: 1.4rem;
`;

const RenewButton = styled(Button)`
  background-color: #7289DA;
  color: #FFFFFF;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #5B6EAE;
    transform: translateY(-2px);
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const QRCodeImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
`;

const CopyButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: #4a4a4a;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  border-radius: 6px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #5a5a5a;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const CopySuccessMessage = styled.p`
  color: #4caf50;
  margin-top: 0.5rem;
`;

const NoPaymentMessage = styled.p`
  color: #ff6b6b;
  margin-top: 0.5rem;
`;

const DarkModal = styled(Modal)`
  .modal-content {
    background-color: #23252A;
    color: #ffffff;
    border-radius: 16px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  }
  .modal-header,
  .modal-footer {
    border: none;
  }
  .modal-body {
    background-color: #23252A;
  }
`;

const SuccessMessage = styled.div`
  background-color: #2ecc71;
  color: white;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

export const GuildManagePayments: React.FC = () => {
  const { user } = useFetchUser();
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || "";
  const { loading, daysLeft, error, refetch } = useFetchGuildPayments(guildId);
  const [showModal, setShowModal] = useState(false);
  const [orderId, setOrderId] = useState<string | null>(null);
  const [qrCodeBase64, setQrCodeBase64] = useState<string | null>(null);
  const [qrCodeText, setQrCodeText] = useState<string | null>(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [noPaymentMessage, setNoPayment] = useState(false);
  const [paymentStep, setPaymentStep] = useState(0);
  const [newDaysLeft, setNewDaysLeft] = useState<number | null>(null);

  const handleClose = () => {
    setShowModal(false);
    setPaymentStep(0);
    setOrderId(null);
    setQrCodeBase64(null);
    setQrCodeText(null);
  };

  const handleRenew = async () => {
    try {
      const response = await createRenewalOrder(guildId);
      const { orderId, qrCode, qrCodeBase64 } = response.data;
      setOrderId(orderId);
      setQrCodeText(qrCode);
      setQrCodeBase64(qrCodeBase64);
      setPaymentStep(1);
      setShowModal(true);
    } catch (error) {
      console.error('Error creating renewal order:', error);
      toast.error('Erro ao criar pedido de renovação. Por favor, tente novamente.', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }
  };

  const handleCopyClick = async () => {
    if (qrCodeText) {
      try {
        await navigator.clipboard.writeText(qrCodeText);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    }
  };

  const handleCheckPaymentStatus = async () => {
    if (!orderId) return;
    try {
      const response = await verifyRenewal(guildId,user?.accessToken, orderId);
      const { isPaid } = response.data;
      if (isPaid) {
        setPaymentStep(2);
        await refetch();
        toast.success('Assinatura renovada com sucesso!', {
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
      } else {
        setNoPayment(true);
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
    }
  };

  if (loading) {
    return (
      <PageWrapper>
        <ContentContainer>
          <Flex justifyContent="center">
            <MoonLoader size={40} color="white" />
          </Flex>
        </ContentContainer>
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <ContentContainer>
          <Title>Erro ao carregar informações de pagamento</Title>
          <p>Por favor, tente novamente mais tarde.</p>
        </ContentContainer>
      </PageWrapper>
    );
  }

  if (loading) {
    return (
      <PageWrapper>
        <ContentContainer>
          <Flex justifyContent="center">
            <MoonLoader size={40} color="#7289DA" />
          </Flex>
        </ContentContainer>
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <ContentContainer>
          <Title>Erro ao carregar informações de pagamento</Title>
          <p>Por favor, tente novamente mais tarde.</p>
        </ContentContainer>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <ContentContainer>
        <div>
          <Toaster position="bottom-right" reverseOrder={false} />
        </div>
        <Title>Gerenciar Assinatura</Title>
        <Card>
          <StatusTitle>Status da Assinatura</StatusTitle>
          <StatusInfo>
            <Calendar size={24} color="#7289DA" />
            <StatusText>
              Dias restantes: <DaysLeft>{daysLeft}</DaysLeft>
            </StatusText>
          </StatusInfo>
          <StatusInfo>
            <DollarSign size={24} color="#7289DA" />
            <StatusText>Preço de renovação: R$10,00 por 30 dias</StatusText>
          </StatusInfo>
          <Flex justifyContent="center" style={{ marginTop: '2rem' }}>
            <RenewButton variant='primary' onClick={handleRenew}>
              <RefreshCw size={18} />
              Renovar por 30 dias
            </RenewButton>
          </Flex>
        </Card>

        <DarkModal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Renovar Assinatura</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {paymentStep === 1 && (
              <>
                <p>Faça o pagamento usando o seguinte QR Code:</p>
                {qrCodeBase64 && (
                  <QRCodeImage src={`data:image/png;base64,${qrCodeBase64}`} alt="QR Code de pagamento" />
                )}
                <p>Ou use a chave PIX abaixo:</p>
                <pre style={{ background: '#2C2F33', padding: '1rem', borderRadius: '8px' }}>{qrCodeText}</pre>
                <CopyButton variant="secondary" onClick={handleCopyClick}>
                  <Copy size={16} />
                  Copiar Copia e Cola
                </CopyButton>
                {copySuccess && <CopySuccessMessage>Copiado com sucesso!</CopySuccessMessage>}
                <p>ID do Pedido: <strong>{orderId}</strong></p>
                {noPaymentMessage && <NoPaymentMessage>Pagamento ainda não foi efetuado!</NoPaymentMessage>}
                <p>Após o pagamento, clique em "Verificar Pagamento" abaixo.</p>
              </>
            )}
            {paymentStep === 2 && (
              <SuccessMessage>
                <CheckCircle size={24} style={{ marginRight: '0.5rem' }} />
                <span>Pagamento confirmado! Sua assinatura foi renovada.</span>
              </SuccessMessage>
            )}
          </Modal.Body>
          <Modal.Footer>
            {paymentStep === 1 && (
              <Button variant="primary" onClick={handleCheckPaymentStatus}>
                Verificar Pagamento
              </Button>
            )}
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </DarkModal>
      </ContentContainer>
    </PageWrapper>
  );
};