import { useCallback, useEffect, useState } from "react";
import { getGuildConfig } from "../api";
import { GuildConfigType } from "../types";

export function useFetchGuildPayments(guildId: string) {
  const [config, setConfig] = useState<GuildConfigType>();
  const [daysLeft, setDaysLeft] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchGuildConfig = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getGuildConfig(guildId);
      setConfig(data);
      if (data.activeUntil) {
        const activeUntil = new Date(data.activeUntil);
        const now = new Date();
        const timeDiff = activeUntil.getTime() - now.getTime();
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
        setDaysLeft(Math.max(0, daysDiff)); // Ensure daysLeft is not negative
      } else {
        setDaysLeft(0);
      }
    } catch (err) {
      console.error("Error fetching guild config:", err);
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [guildId]);

  useEffect(() => {
    fetchGuildConfig();
  }, [fetchGuildConfig]);

  const refetch = useCallback(async () => {
    await fetchGuildConfig();
  }, [fetchGuildConfig]);

  return { config, daysLeft, loading, error, refetch };
}