import { useEffect, useState } from "react";
import {
  getGuildConfig,
  getGuildEmojis,
  getGuildRoles,
  getGuildTextChannels,
} from "../api";
import { GuildConfigType, GuildRole, PartialGuildChannel } from "../types";

export function useGuildVerify(guildId: string) {
  const [config, setConfig] = useState<GuildConfigType>();
  const [channels, setChannels] = useState<PartialGuildChannel[]>();
  const [roles, setRoles] = useState<GuildRole[]>();
  const [selectedVerifyChannel, setSelectedVerifyChannel] =
    useState<string>("");
  const [selectedVerifyFichaChannel, setSelectedVerifyFichaChannel] =
    useState<string>("");
  const [selectedVerifyLogsChannel, setSelectedVerifyLogsChannel] =
    useState<string>("");
  const [verifyEmbedMessage, setVerifyEmbedMessage] = useState<string>("");
  const [verifyEmbedColor, setVerifyEmbedColor] = useState<string>("");
  const [verifyEmbedImg, setVerifyEmbedImg] = useState<string>("");
  const [verifyRoles, setVerifyRoles] = useState<any[]>([]);
  const [verifyAnyRoles, setVerifyAnyRoles] = useState<any[]>([]);
  const [verifyRole, setVerifyRole] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getGuildConfig(guildId)
      .then(({ data }) => {
        setConfig(data);
        setSelectedVerifyChannel(data.verifyChannelId || "");
        setSelectedVerifyFichaChannel(data.verifyFichasChannelId || "");
        setSelectedVerifyLogsChannel(data.verifyLogsChannelId || "");
        setVerifyEmbedMessage(data.verifyEmbedText || "");
        setVerifyEmbedImg(data.verifyEmbedImg || "");
        setVerifyRoles(data.verifyPerms);
        setVerifyRole(data.verifyRole);
        setVerifyAnyRoles(data.verifyAnyPerms);
        setVerifyEmbedColor(data.verifyEmbedColor);
        getGuildTextChannels(guildId).then(({ data }) => {
          setChannels(data);
          getGuildRoles(guildId).then(({ data }) => {
            setRoles(data);
          });
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return {
    config,
    channels,
    roles,
    loading,
    selectedVerifyChannel,
    setSelectedVerifyChannel,
    selectedVerifyFichaChannel,
    setSelectedVerifyFichaChannel,
    selectedVerifyLogsChannel,
    setSelectedVerifyLogsChannel,
    verifyEmbedMessage,
    setVerifyEmbedMessage,
    verifyEmbedColor,
    setVerifyEmbedColor,
    verifyEmbedImg,
    setVerifyEmbedImg,
    verifyRoles,
    setVerifyRoles,
    verifyAnyRoles,
    setVerifyAnyRoles,
    verifyRole,
    setVerifyRole
  };
}
