import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Card } from "react-bootstrap";
import MainNavbar from "../components/MainNavbar";
import { AiOutlineRobot } from "react-icons/ai";
import { GiCheckMark } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';

const PageWrapper = styled.div`
  background-color: #18191D;
  color: #ffffff;
  min-height: 100vh;
`;

const ContentContainer = styled(Container)`
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

const Heading = styled.h1`
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #ffffff;
`;

const Subheading = styled.p`
  font-size: 1.25rem;
  color: #a0a0a0;
  margin-bottom: 2rem;
`;

const FeatureCard = styled(Card)`
  background-color: #212428;
  border: none;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const OfferBanner = styled.div`
  background-color: #7289da;
  color: white;
  text-align: center;
  padding: 0.75rem;
  font-weight: bold;
  font-size: 0.9rem;
`;

const PriceTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

const OldPrice = styled.span`
  text-decoration: line-through;
  font-size: 1.5rem;
  color: #a0a0a0;
  margin-right: 1rem;
`;

const NewPrice = styled.span`
  font-size: 2.5rem;
  font-weight: bold;
  color: #43b581;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
  max-height: 200px;
  overflow-y: auto;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  color: #ffffff;
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #7289da;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #5b6eae;
  }
`;

const features = [
  'Sistema de Moderação',
  'Sistema de Bem-Vindo',
  'Sistema de Primeira Dama',
  'Sistema de Migração',
  'Sistema de Registro',
  'Sistema de Instagram',
  'Sistema de Membro Ativo',
  'Sistema de Verificação',
  'Sistema de Tellonym',
  'Sistema de Seja Membro',
  'Contador de Call',
  'Contador de Membro',
  'Contador de Tempo em Call'
];

export const HomePage = () => {
  const navigate = useNavigate();
  
  const redirectToLogin = () => {
    navigate('/api/auth/login');
  };

  return (
    <PageWrapper>
      <MainNavbar />
      <ContentContainer>
        <Row className="align-items-center">
          <Col lg={6} className="mb-5 mb-lg-0">
            <Heading>Eleve seu servidor de Discord</Heading>
            <Subheading>
              Moderação, proteção e entretenimento em um único bot poderoso.
            </Subheading>
          </Col>
          <Col lg={6}>
            <FeatureCard>
              <OfferBanner>OFERTA ESPECIAL - 66% DE DESCONTO</OfferBanner>
              <Card.Body className="p-4">
                <div className="text-center mb-4">
                  <AiOutlineRobot size={60} color="#7289da" className="mb-3" />
                  <h2 className="h3 mb-0" style={{ color: '#ffffff' }}>Bot Mensal</h2>
                </div>
                
                <PriceTag>
                  <OldPrice>R$30,00</OldPrice>
                  <NewPrice>R$10,00</NewPrice>
                </PriceTag>
                <p className="text-center" style={{ color: '#a0a0a0' }}>por mês</p>
                
                <FeatureList>
                  {features.map((feature, index) => (
                    <FeatureItem key={index}>
                      <GiCheckMark color="#43b581" className="me-2" />
                      <span>{feature}</span>
                    </FeatureItem>
                  ))}
                </FeatureList>
                
                <StyledButton onClick={redirectToLogin}>
                  Aproveite Agora
                </StyledButton>
              </Card.Body>
            </FeatureCard>
          </Col>
        </Row>
      </ContentContainer>
    </PageWrapper>
  );
};