import React, { useContext, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { GuildContext } from "../../utils/context/GuildContext";
import { useGuildSupport } from "../../utils/hooks/useGuildSupport";
import { updateSuporteConfig } from "../../utils/api";
import { ChromePicker, ColorResult } from "react-color";
import rgbHex from "rgb-hex";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { MoonLoader } from "react-spinners";

// Define theme type
type Theme = {
  background: string;
  textPrimary: string;
  textSecondary: string;
  borderColor: string;
  inputBackground: string;
  primaryColor: string;
  primaryColorHover: string;
  secondaryColor: string;
  secondaryColorHover: string;
  alertBackground: string;
  alertText: string;
};

// Theme matching original dark style
const theme: Theme = {
  background: '#1112155d',
  textPrimary: '#ffffff',
  textSecondary: '#cccccc',
  borderColor: '#6f6f6f',
  inputBackground: '#1112155d',
  primaryColor: '#4a90e2',
  primaryColorHover: '#357abD',
  secondaryColor: '#6c757d',
  secondaryColorHover: '#5a6268',
  alertBackground: '#d4edda',
  alertText: '#155724',
};

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 35px 15px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.textPrimary};
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.textPrimary};
`;

const Section = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.textSecondary};
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 4px;
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.textPrimary};
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 4px;
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.textPrimary};
  min-height: 100px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

interface ButtonProps {
  primary?: boolean;
}

const Button = styled.button<ButtonProps>`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;

  background-color: ${props => props.primary ? props.theme.primaryColor : props.theme.secondaryColor};
  color: ${props => props.primary ? 'white' : props.theme.textPrimary};
  
  &:hover {
    background-color: ${props => props.primary ? props.theme.primaryColorHover : props.theme.secondaryColorHover};
  }
`;

export const GuildSupportPage: React.FC = () => {
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || "";
  const {
    config,
    channels,
    roles,
    loading,
    selectedSuporteChannel,
    setSelectedSuporteChannel,
    suporteChannelMsg,
    setSuporteChannelMsg,
    suporteChannelImg,
    setSuporteChannelImg,
    suporteRoles,
    setSuporteRoles,
    suporteColor,
    setSuporteColor,
  } = useGuildSupport(guildId);

  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  const handleChannelChange = (e: any) => {
    setSelectedSuporteChannel(e.value);
  };

  const handleSubmit = async () => {
    try {
      await updateSuporteConfig(
        guildId,
        selectedSuporteChannel,
        suporteRoles,
        suporteChannelMsg,
        suporteChannelImg,
        suporteColor
      );
      toast.success("Suporte atualizado!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } catch (err) {
      console.error(err);
      toast.error("Erro ao atualizar suporte", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  const channelOptions = channels?.map((channel) => ({
    value: channel.id,
    label: `#${channel.name}`,
  }));

  const roleOptions = roles?.map((role) => ({
    value: role.id,
    label: `${role.name}`,
  }));

  const defaultSuporteRoles = suporteRoles?.map((role) =>
    roles?.find((guildRole) => guildRole.id === role)
  );

  const defaultSuporteOptions = defaultSuporteRoles?.map((role) => ({
    value: role?.id,
    label: `${role?.name}`,
  }));

  const selectStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: theme.inputBackground,
      borderColor: theme.borderColor,
      color: theme.textPrimary,
    }),
    option: (provided: any, state: { isSelected: boolean; isFocused: boolean }) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#41444f' : state.isFocused ? '#18191d93' : theme.inputBackground,
      color: theme.textPrimary,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: theme.textPrimary,
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#3a3d4692',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: theme.textPrimary,
    }),
    menuList: (provided: any) => ({
      ...provided,
      backgroundColor: '#18191d93',
      maxHeight: '200px',
    }),
  };

  if (loading || !channels || !roles) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <MoonLoader size={30} color={theme.textPrimary} />
      </Container>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <div>
          <Toaster position="bottom-right" reverseOrder={false} />
        </div>
        <Title>Editar Canal do Painel de Suporte</Title>
        
        <Section>
          <Label htmlFor="currentChannel">Canal Atual:</Label>
          <Select
            styles={selectStyles}
            options={channelOptions}
            onChange={handleChannelChange}
            value={channelOptions?.find((op) => op.value === selectedSuporteChannel)}
            placeholder="Por favor selecione um canal."
          />
        </Section>

        <Section>
          <Label htmlFor="responsibleRoles">Cargos Responsáveis:</Label>
          <Select
            styles={selectStyles}
            isMulti
            closeMenuOnSelect={false}
            value={defaultSuporteOptions}
            options={roleOptions}
            onChange={(e) => {
              const selects = e.map((entry: any) => entry.value);
              setSuporteRoles(selects);
            }}
            placeholder="Por favor selecione os cargos responsáveis que serão marcados no suporte."
          />
        </Section>

        <Section>
          <Label htmlFor="embedColor">Cor da Embed:</Label>
          <div
            style={{
              maxWidth: "100px",
              minHeight: "30px",
              border: "3px solid #2a2c33",
            }}
            onClick={handleClick}
          >
            <div style={{ color: suporteColor, background: suporteColor }}>
              {suporteColor}
            </div>
          </div>
          {show ? (
            <div>
              <div
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                }}
                onClick={handleClick}
              />
              <ChromePicker
                onChange={(c) => {
                  setSuporteColor(
                    "#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a)
                  );
                }}
                color={suporteColor || ""}
              />
            </div>
          ) : null}
        </Section>

        <Section>
          <Label htmlFor="message">Texto Atual:</Label>
          <Textarea
            id="message"
            value={suporteChannelMsg}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setSuporteChannelMsg(e.target.value)}
            placeholder="Insira o texto que será utilizado na Embed de suporte."
          />
        </Section>

        <Section>
          <Label htmlFor="imageUrl">Imagem Atual:</Label>
          <Input
            id="imageUrl"
            value={suporteChannelImg}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSuporteChannelImg(e.target.value)}
            placeholder="Insira o link da imagem que será utilizada na Embed de suporte."
          />
        </Section>

        <ButtonGroup>
          <Button>Resetar</Button>
          <Button primary onClick={handleSubmit}>Salvar</Button>
        </ButtonGroup>
      </Container>
    </ThemeProvider>
  );
};

export default GuildSupportPage;