import React from 'react';
import styled from 'styled-components';
import { PartialGuild } from '../utils/types';

const GuildItemWrapper = styled.div`
  background-color: #212428;
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
`;

const GuildIcon = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 1rem;
`;

const GuildName = styled.h3`
  font-size: 1.2rem;
  color: #ffffff;
  margin: 0;
`;

interface Props {
  guild: PartialGuild;
}

export const GuildMenuItem: React.FC<Props> = ({ guild }) => {
  const iconUrl = guild.icon
    ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`
    : 'https://cdn.discordapp.com/embed/avatars/0.png';

  return (
    <GuildItemWrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <GuildIcon src={iconUrl} alt={guild.name} />
        <GuildName>{guild.name}</GuildName>
      </div>
    </GuildItemWrapper>
  );
};