import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { Button, Flex, InputField, Title } from "../../utils/styles";
import { MoonLoader } from "react-spinners";
import Select from "react-select";
import { GuildContext } from "../../utils/context/GuildContext";
import { useFetchGuildpanelaConfig } from "../../utils/hooks/useFetchGuildPanela";
import { updatePanelaPermissions } from "../../utils/api";

interface SelectOption {
  value: string;
  label: string;
}

export const GuildPanelaPage: React.FC = () => {
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || "";
  const { config, roles, panelaConfig, loading, setPanelaConfig } = useFetchGuildpanelaConfig(guildId);

  const options: SelectOption[] = roles?.map((role) => ({
    value: role.id,
    label: role.name,
  })) || [];

  const handleSubmit = () => {
    try {
      updatePanelaPermissions(guildId, panelaConfig);
      toast.success("Permissões atualizadas!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar permissões!");
    }
  };

  const handleAddFields = () => {
    setPanelaConfig([...panelaConfig, { 
      permId: "",
      allowedRoles: [{ roleId: "", amount: 1 }]
    }]);
  };

  const handleRemovePermission = (index: number) => {
    const updatedPermissions = [...panelaConfig];
    updatedPermissions.splice(index, 1);
    setPanelaConfig(updatedPermissions);
  };

  const handleChangePermission = (index: number, value: string) => {
    const updatedPermissions = [...panelaConfig];
    updatedPermissions[index].permId = value;
    setPanelaConfig(updatedPermissions);
  };

  const handleAddRole = (permissionIndex: number) => {
    const updatedPermissions = [...panelaConfig];
    updatedPermissions[permissionIndex].allowedRoles.push({ roleId: "", amount: 1 });
    setPanelaConfig(updatedPermissions);
  };

  const handleRemoveRole = (permissionIndex: number, roleIndex: number) => {
    const updatedPermissions = [...panelaConfig];
    updatedPermissions[permissionIndex].allowedRoles.splice(roleIndex, 1);
    setPanelaConfig(updatedPermissions);
  };

  const handleChangeRole = (permissionIndex: number, roleIndex: number, field: keyof typeof panelaConfig[number]['allowedRoles'][number], value: string | number) => {
    const updatedPermissions = [...panelaConfig];
    updatedPermissions[permissionIndex].allowedRoles[roleIndex][field] = value as never;
    setPanelaConfig(updatedPermissions);
  };

  const colorStyles = {
    control: (Styles: object) => ({
      ...Styles,
      backgroundColor: "#1112155d",
      border: "1px solid #6f6f6f",
      outline: "none",
      fontFamily: "DM Sans",
      color: "#fff",
      height: "auto",
    }),
    option: (
      Styles: object,
      {
        data,
        isDisabled,
        isFocused,
        isSelected,
      }: {
        data: object;
        isDisabled: boolean;
        isFocused: boolean;
        isSelected: boolean;
      }
    ) => {
      return {
        ...Styles,
        color: "#fff",
        background: isFocused
          ? "#18191d93"
          : isSelected
          ? "#41444f"
          : "#18191d",
        fontFamily: "DM Sans",
        border: "none",
        borderRadius: "none",
      };
    },
    multiValue: (Styles: object) => {
      return {
        ...Styles,
        color: "#ffffff",
        fontFamily: "DM Sans",
        backgroundColor: "#3a3d4692",
      };
    },
    singleValue: (Styles: object) => {
      return { ...Styles, color: "#fff", fontFamily: "DM Sans" };
    },
    multiValueLabel: (Styles: object) => {
      return {
        ...Styles,
        color: "#ffffff",
      };
    },
    menuList: (Styles: object) => {
      return {
        background: "#18191d93",
        height: "200px",
        overflow: "scroll",
      };
    },
    placeholder: (Styles: object) => {
      return { ...Styles, color: "#ffffff" };
    },
  };

  return (
    <Container style={{ padding: "35px 15px" }}>
      <div>
        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
      <Title>Configuração de Panela</Title>
      {config && roles && !loading ? (
        <>
          <section style={{ marginTop: "15px" }}>
            <div>
              <Title style={{ paddingTop: "5px", marginBottom: "3px" }}>
                Permissões de Panela
              </Title>
              {panelaConfig.map((permission, permissionIndex) => (
                <div key={permissionIndex} style={{ marginBottom: "20px", padding: "15px", border: "1px solid #6f6f6f", borderRadius: "5px" }}>
                  <Select
                    options={options}
                    styles={colorStyles}
                    value={options.find(opt => opt.value === permission.permId)}
                    onChange={(selectedOption) => handleChangePermission(permissionIndex, selectedOption?.value || "")}
                    placeholder="Selecione a permissão"
                  />
                  {permission.allowedRoles.map((role, roleIndex) => (
                    <Flex key={roleIndex} style={{ marginTop: "10px" }}>
                      <Select
                        options={options}
                        styles={colorStyles}
                        value={options.find(opt => opt.value === role.roleId)}
                        onChange={(selectedOption) => handleChangeRole(permissionIndex, roleIndex, 'roleId', selectedOption?.value || "")}
                        placeholder="Selecione o cargo"
                      />
                      <InputField
                        type="number"
                        value={role.amount}
                        onChange={(e) => handleChangeRole(permissionIndex, roleIndex, 'amount', parseInt(e.target.value))}
                        placeholder="Quantidade"
                        style={{ marginLeft: "10px", width: "100px" }}
                      />
                      <Button variant="negative" onClick={() => handleRemoveRole(permissionIndex, roleIndex)} style={{ marginLeft: "10px" }}>
                        Remover Cargo
                      </Button>
                    </Flex>
                  ))}
                  <Button variant="positive" onClick={() => handleAddRole(permissionIndex)} style={{ marginTop: "10px" }}>
                    Adicionar Cargo
                  </Button>
                  <Button variant="negative" onClick={() => handleRemovePermission(permissionIndex)} style={{ marginTop: "10px", marginLeft: "10px" }}>
                    Remover Permissão
                  </Button>
                </div>
              ))}
              <Flex justifyContent="flex-end" style={{ marginTop: "20px" }}>
                <Button
                  variant="positive"
                  type="button"
                  style={{ marginRight: "8px" }}
                  onClick={handleAddFields}
                >
                  Adicionar Permissão
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  Salvar
                </Button>
              </Flex>
            </div>
          </section>
        </>
      ) : (
        <Flex justifyContent="center">
          <MoonLoader size={40} color="white" />
        </Flex>
      )}
    </Container>
  );
};