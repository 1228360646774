import { useEffect, useState } from "react";
import { getGuildConfig, getGuildRoles } from "../api";
import { GuildConfigType, GuildRole, panelaPerm } from "../types";

export function useFetchGuildpanelaConfig(guildId: string) {
  const [config, setConfig] = useState<GuildConfigType>();
  const [roles, setRoles] = useState<GuildRole[]>();
  const [panelaConfig, setPanelaConfig] = useState<panelaPerm[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getGuildConfig(guildId)
      .then(({ data }) => {
        setConfig(data);
        if (!data.panelaConfig || data.panelaConfig.length === 0) {
            data.panelaConfig = [{ permId: "", allowedRoles: [{ roleId: "", amount: 1 }] }];
          }
        setPanelaConfig(data.panelaConfig);
        return getGuildRoles(guildId);
      })
      .then(({ data }) => {
        setRoles(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return { config, roles, panelaConfig, loading, setPanelaConfig };
}
