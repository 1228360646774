import { useContext, useState } from "react";
import { Container } from "react-bootstrap";
import { MoonLoader } from "react-spinners";
import { GuildContext } from "../../utils/context/GuildContext";
import { Title, InputArea, Button, Flex, TextArea } from "../../utils/styles";
import toast, { Toaster } from "react-hot-toast";
import Select, { MultiValue } from "react-select";
import { useGuildCounter } from "../../utils/hooks/useGuildCounter";
import { emojiOptions } from "../../utils/types";
import { updateMemberCounter, updateMigrarConfig, updateVerifyConfig } from "../../utils/api";
import { useGuildMigrar } from "../../utils/hooks/useGuildMigrar";
import { ChromePicker } from "react-color";
import rgbHex from "rgb-hex";
import { useGuildVerify } from "../../utils/hooks/useFetchGuildVerify";

export const GuildVerifyConfig = () => {
  const { guild } = useContext(GuildContext);
  const guildId = (guild && guild.id) || "";
  const {
    config,
    channels,
    roles,
    loading,
    selectedVerifyChannel,
    setSelectedVerifyChannel,
    selectedVerifyFichaChannel,
    setSelectedVerifyFichaChannel,
    selectedVerifyLogsChannel,
    setSelectedVerifyLogsChannel,
    verifyEmbedMessage,
    setVerifyEmbedMessage,
    verifyEmbedColor,
    setVerifyEmbedColor,
    verifyEmbedImg,
    setVerifyEmbedImg,
    verifyRoles,
    setVerifyRoles,
    verifyAnyRoles,
    setVerifyAnyRoles,
    verifyRole,
    setVerifyRole
  } = useGuildVerify(guildId);

  const [show, setShow] = useState(false);
  const channelOptions = channels?.map((channel) => ({
    value: channel.id,
    label: `#${channel.name}`,
  }));

  const handleClick = () => {
    setShow(!show);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleVerifyChannelChange = (e: any) => {
    setSelectedVerifyChannel(e.value);
  };
  const handleVerifyFichaChannelChange = (e: any) => {
    setSelectedVerifyFichaChannel(e.value);
  };
  const handleVerifyLogsChannelChange = (e: any) => {
    setSelectedVerifyLogsChannel(e.value);
  };

  const handleSubmit = async () => {
    try {
      updateVerifyConfig(
        guildId,
        selectedVerifyChannel,
        selectedVerifyFichaChannel,
        selectedVerifyLogsChannel,
        verifyRole,
        verifyRoles,
        verifyAnyRoles,
        verifyEmbedColor,
        verifyEmbedMessage,
        verifyEmbedImg,
      );
    } catch (err) {
      return console.log(err);
    } finally {
      toast.success("Embed de Verificação atualizado!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };
  const roleOptions = roles?.map((role) => ({
    value: role.id,
    label: `${role.name}`,
  }));
  
  const defaultVerifyRoles = verifyRoles?.map((role) =>
    roles?.find((guildRole) => guildRole.id === role)
  );
  const DefaultVerifyOptions = defaultVerifyRoles?.map((role) => ({
    value: role?.id,
    label: `${role?.name}`,
  }));

  const defaultVerifyAnyRoles = verifyAnyRoles?.map((role) =>
    roles?.find((guildRole) => guildRole.id === role)
  );
  const DefaultVerifyAnyOptions = defaultVerifyAnyRoles?.map((role) => ({
    value: role?.id,
    label: `${role?.name}`,
  }));

  const colorStyles = {
    control: (Styles: object) => ({
      ...Styles,
      backgroundColor: "#1112155d",
      border: "1px solid #6f6f6f",
      outline: "none",
      fontFamily: "DM Sans",
      color: "#fff",
      height: "auto",
    }),
    option: (
      Styles: object,
      {
        data,
        isDisabled,
        isFocused,
        isSelected,
      }: {
        data: object;
        isDisabled: boolean;
        isFocused: boolean;
        isSelected: boolean;
      }
    ) => {
      return {
        ...Styles,
        color: "#fff",
        background: isFocused
          ? "#18191d93"
          : isSelected
          ? "#41444f"
          : "#18191d",
        fontFamily: "DM Sans",
        border: "none",
        borderRadius: "none",
      };
    },
    multiValue: (Styles: object) => {
      return {
        ...Styles,
        color: "#ffffff",
        fontFamily: "DM Sans",
        backgroundColor: "#3a3d4692",
      };
    },
    singleValue: (Styles: object) => {
      return { ...Styles, color: "#fff", fontFamily: "DM Sans" };
    },
    multiValueLabel: (Styles: object) => {
      return {
        ...Styles,
        color: "#ffffff",
      };
    },
    menuList: (Styles: object) => {
      return {
        background: "#18191d93",
        height: "200px",
        overflow: "scroll",
      };
    },
    placeholder: (Styles: object) => {
      return { ...Styles, color: "#ffffff" };
    },
  };
  return (
    <Container style={{ padding: "35px 15px" }}>
      <div>
        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
      {roles && config && channels && !loading ? (
        <>
          <Title>Editar Painel de Verificação</Title>
          <section>
            <div>
              <label htmlFor="currentChannel">Canal da Embed:</label>
            </div>
            <Select
              styles={colorStyles}
              options={channelOptions}
              onChange={(e) => {
                handleVerifyChannelChange(e);
              }}
              defaultValue={channelOptions?.find((op) => {
                return op.value === config?.verifyChannelId;
              })}
              placeholder={"Por favor selecione um canal para enviar a embed."}
            />
          </section>
          <section>
            <div>
              <label htmlFor="currentChannel">Canal das fichas:</label>
            </div>
            <Select
              styles={colorStyles}
              options={channelOptions}
              onChange={(e) => {
                handleVerifyFichaChannelChange(e);
              }}
              defaultValue={channelOptions?.find((op) => {
                return op.value === config?.verifyFichasChannelId;
              })}
              placeholder={"Por favor selecione um canal para enviar as fichas."}
            />
          </section>
          <section>
            <div>
              <label htmlFor="currentChannel">Canal de logs:</label>
            </div>
            <Select
              styles={colorStyles}
              options={channelOptions}
              onChange={(e) => {
                handleVerifyLogsChannelChange(e);
              }}
              defaultValue={channelOptions?.find((op) => {
                return op.value === config?.verifyLogsChannelId;
              })}
              placeholder={"Por favor selecione um canal para as logs de verificados."}
            />
          </section>
          <section>
            <div>
              <label htmlFor="currentChannel">Cargos de verificado:</label>
            </div>
            <Select
              styles={colorStyles}
              closeMenuOnSelect={false}
              defaultValue={roleOptions?.find((op) => {
                return op.value === config?.verifyRole;
              })}
              options={roleOptions}
              onChange={(e) => {
                roles.find((role)=>{
                    if(role.id === e?.value && role.permissions !== '8'){
                        setVerifyRole(e?.value);
                    }
                })
              }}
              placeholder={
                "Por favor selecione o cargo que vai permitir o membro ver o servidor."
              }
            />
          </section>
          <section>
            <div>
              <label htmlFor="currentChannel">Cargos de Verificadores:</label>
            </div>
            <Select
              styles={colorStyles}
              isMulti
              closeMenuOnSelect={false}
              defaultValue={DefaultVerifyOptions}
              options={roleOptions}
              onChange={(e) => {
                const selects = e.map((entry) => entry.value);
                setVerifyRoles(selects);
              }}
              placeholder={
                "Por favor selecione os cargos responsáveis por receber fichas."
              }
            />
          </section>
          <section>
            <div>
              <label htmlFor="currentChannel">Cargos que pode aceitar qualquer ficha:</label>
            </div>
            <Select
              styles={colorStyles}
              isMulti
              closeMenuOnSelect={false}
              defaultValue={DefaultVerifyAnyOptions}
              options={roleOptions}
              onChange={(e) => {
                const selects = e.map((entry) => entry.value);
                setVerifyAnyRoles(selects);
              }}
              placeholder={
                "Por favor selecione os cargos que poderão aceitar ficha das outras pessoas."
              }
            />
          </section>
          <section style={{ marginTop: "15px" }}>
            <div>
              <label htmlFor="message">Cor da Embed:</label>
              <div
                style={{
                  maxWidth: "100px",
                  minHeight: "30px",
                  border: "3px solid #2a2c33",
                }}
                onClick={handleClick}
              >
                <div style={{ color: verifyEmbedColor, background: verifyEmbedColor }}>
                  {verifyEmbedColor}
                </div>
              </div>
              {show ? (
                <div>
                  <div
                    style={{
                      position: "fixed",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={handleClick}
                  />
                  <ChromePicker
                    onChange={(c) => {
                      setVerifyEmbedColor(
                        "#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a)
                      );
                    }}
                    color={verifyEmbedColor || ""}
                  />
                </div>
              ) : null}
            </div>
          </section>
          <section style={{ marginTop: "15px" }}>
            <div>
              <label htmlFor="message">Texto Atual:</label>
              <TextArea
                maxLength={4096}
                style={{ marginTop: "3px" }}
                id="message"
                defaultValue={config.verifyEmbedText}
                onChange={(e) => {
                  setVerifyEmbedMessage(e.target.value);
                }}
                placeholder="Insira o texto que será utilizado na Embed de verificação."
              />
            </div>
          </section>
          <section style={{ marginTop: "15px" }}>
            <div>
              <label htmlFor="message">Imagem Atual:</label>
              <InputArea
                style={{ marginTop: "3px" }}
                id="message"
                defaultValue={config.verifyEmbedImg}
                onChange={(e) => {
                  setVerifyEmbedImg(e.target.value);
                }}
                placeholder="Insira o link da imagem que será utilizada na Embed de verificação."
              />
            </div>
          </section>
          <Flex justifyContent="flex-end">
            <Button
              variant="secondary"
              type="button"
              style={{ marginRight: "8px" }}
            >
              Resetar
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Salvar
            </Button>
          </Flex>
        </>
      ) : (
        <Flex justifyContent="center">
          <MoonLoader size={30} color="white" />
        </Flex>
      )}
    </Container>
  );
};
